import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import AreasAtuacao from 'components/AreasAtuacao'

const AreasAtuacaoPage = ({ data, location, pageContext }) => {
  const wordpressPage = data.wordpressWpGatsbyPage.acf
  const AreaItens = data.allWordpressWpExpertiseAreaPage.edges

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressPage.title}
        description={wordpressPage.subtitle}
        canonical={wordpressPage.canonical}
        pathName={location.pathname}
      />

      <AreasAtuacao 
        pageData={wordpressPage}
        areaItens={AreaItens}
      />
    </Layout>
  )
}

AreasAtuacaoPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default AreasAtuacaoPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        canonical
        demonstrative_text
        btn_saiba_mais_areas
        img_areas {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allWordpressWpExpertiseAreaPage(sort: {fields: acf___ordem_desktop, order: ASC}) {
      edges {
        node {
          acf {
            description_area
            link_atuacao
            nome
            icone {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            ordem_desktop
          }
        }
      }
    }
  }
`
