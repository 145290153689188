import styled from 'styled-components'
import media from 'styled-media-query'
import PinkLine from '../shared/PinkLine'
import { ContainerDasa } from '../shared/container'
import { alvTokens } from '@dasa-health/alma-tokens'
import { Link } from '@reach/router'

export const AreasAtuacaoContainer = styled.div`
  position: relative;
`

export const AreasContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding-bottom: 12rem;
  
  ${media.lessThan('medium')`
    padding-bottom: 3.2rem;
  `}
`

export const Container = styled(ContainerDasa)``

export const PageTitle = styled.h1`
  font-size: var(--fontTitle);
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--blue);
  margin-bottom: 2.4rem;

  ${media.lessThan('medium')`
    font-size: var(--font20);
  `}
`

export const PageDescription = styled.p`
  font-size: var(--fontDefault);
  color: ${alvTokens.ColorPaletteGray70};
  line-height: 170%;
  max-width: 76rem;
  margin-bottom: 4.8rem;
  
  ${media.lessThan('medium')`
    margin-bottom: 6.4rem;
  `}
`

export const AreaItensContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`

export const AreaItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 31.1rem;
  min-height: 31rem;
  background: var(--neutral00);
  padding: 2.4rem;
  border: 1px solid rgba(22, 22, 22, 0.24);
  border-radius: 8px;
  position: relative;
  z-index: 1;

  ${media.lessThan('medium')`
    min-height: 28.5rem;
  `}
`

export const AreaImg = styled.div`
  width: 6.4rem;
  margin-bottom: 1.6rem;
`

export const AreaTitle = styled.h2`
  margin-bottom: 1.6rem;
`

export const AreaTitleLink = styled(Link)`
  color: ${alvTokens.ColorBrandPrimary10};
  font-size: var(--font16);
  line-height: 150%;
  text-decoration: none;
`

export const AreaDescription = styled.p`
  font-size: var(--font16);
  color: ${alvTokens.ColorPaletteGray70};
  line-height: 150%;
  margin-bottom: 1.6rem;
  min-height: 12rem;
`

export const AreaLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: var(--fontDefault);
  color: ${alvTokens.ColorBrandPrimary10};
  font-weight: 700;
  line-height: 115%;
  text-decoration: none;
  bottom: 2.4rem;
  
  svg {
    margin-left: .4rem;
  }
`

export const ImgPeoples = styled.div`
  position: absolute;
  bottom: 7.8rem;
  right: 0;
  width: 38.5rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Line = styled(PinkLine)``
