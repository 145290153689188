import React from 'react'
import Img from 'gatsby-image'
import * as S from './styled'
import { ChevronRight } from '@dasa-health/alma-icons'

const AreasAtuacao = ({pageData, areaItens}) => {
  return (
      <S.AreasAtuacaoContainer>
        <S.AreasContainer>
          <S.Container>
            <S.PageTitle>
              {pageData.title}
            </S.PageTitle>

            <S.PageDescription>
              {pageData.demonstrative_text}
            </S.PageDescription>

            <S.AreaItensContainer>
            {areaItens.map(({ node: { acf: { icone, link_atuacao, nome, description_area } } }) => (
                <S.AreaItem>
                  <S.AreaImg>
                    <Img
                      fluid={icone.localFile.childImageSharp.fluid}
                      alt={icone.alt_text}
                    />
                  </S.AreaImg>

                  <S.AreaTitle>
                    <S.AreaTitleLink to={link_atuacao}>
                      {nome}
                    </S.AreaTitleLink>
                  </S.AreaTitle>

                  <S.AreaDescription>
                    {description_area}
                  </S.AreaDescription>

                  <S.AreaLink to={link_atuacao}>
                    {pageData.btn_saiba_mais_areas}

                    <ChevronRight />
                  </S.AreaLink>
                </S.AreaItem>
              ))}
            </S.AreaItensContainer>
          </S.Container>
        </S.AreasContainer>

        <S.ImgPeoples>
          <Img
            fluid={pageData.img_areas.localFile.childImageSharp.fluid}
            alt={pageData.img_areas.alt_text}
          />
        </S.ImgPeoples>

        <S.Line />
      </S.AreasAtuacaoContainer>
  )
}

export default AreasAtuacao